import React from 'react';
import moment from 'moment';
import Main from '../Layouts/Main';
import { connect } from 'react-redux';
import { getSpecificMeasurementById } from '../Reducers/Measurement';
import Card from '../Components/Card';
import Loading from '../Components/Loading';
import { withRouter } from '../Helpers';
import StatsCard from '../Components/StatsCard';
import CustomDoughnut from '../Components/Graphs/CustomDoughnut';

class Scan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            failed: false
        }
    }

    componentDidMount() {
        if (!this.props.params.id) {
            this.setState({
                failed: true
            });
        }
        if (!this.props.measurementData) {
            console.log('NOT FOUND');
            this.props.dispatch(getSpecificMeasurementById(this.props.params.id));
        }
    }

    renderTable() {
        const { header, content, timestamp } = this.props.measurementData;
        const { weightGraph, waterGraph, muscleFatGraph } = header;
        const percentages = weightGraph;

        const waterGraphLabels = waterGraph.otherPercentage.value ? ['Intracellular Water', 'Extracellular Water', 'Other Water'] : ['Intracellular Water', 'Extracellular Water'];
        const waterGraphValues =  waterGraph.otherPercentage.value ? [waterGraph.icwPercentage.value, waterGraph.ecwPercentage.value, waterGraph.otherPercentage.value] : [waterGraph.icwPercentage.value, waterGraph.ecwPercentage.value];
        const waterGraphDescriptions =  waterGraph.otherPercentage.value ? [waterGraph.icwPercentage.description, waterGraph.ecwPercentage.description, waterGraph.otherPercentage.description] : [waterGraph.icwPercentage.description, waterGraph.ecwPercentage.description];

        const {
            age,
            weight,
            fitnessScore,
            bmi
        } = header;
        
        console.log('percentage', percentages);
        // weight = bodycellmass + bodycellmass + skeletalmass + totalbodywater + bodyfatmass
        return (
            <>
                <Card color="dark">
                    <h1>Scanned {moment(timestamp).fromNow()}</h1>
                    <h6>{moment(timestamp).format('DD/MM/YYYY [at] HH:mm')}</h6>
                </Card>
                <div className="row">
                    <StatsCard color="light" name="Age" icon={<i className="fas fa-birthday-cake"></i>} value={age.value} />
                    <StatsCard color="light" name="Weight" icon={<i className="fa fa-weight" />} value={weight.value + 'kg'} />
                    <StatsCard color="light" name="Fitness Score" icon={<i className="fas fa-trophy"></i>} value={fitnessScore.value} />
                    <StatsCard color="light" name="BMI" icon={<i className="fas fa-calculator"></i>} value={bmi.value} />
                </div>
                <Card color="light">
                    <div className="row">
                        <div className="col-sm-12 col-lg-4">
                            <h3 className="text-center mt-3">Weight Analysis</h3>
                            <CustomDoughnut
                                labels={['Water', 'Fat', 'Minerals', 'Protein']}
                                data={[percentages.totalBodyWaterPercentage.value, percentages.bodyFatMassPercentage.value, percentages.mineralsPercentage.value, percentages.proteinPercentage.value]}
                                descriptions={[percentages.totalBodyWaterPercentage.description, percentages.bodyFatMassPercentage.description, percentages.mineralsPercentage.description, percentages.proteinPercentage.description]}
                                colors={['aqua', 'red', 'yellow', 'blue']}
                                unit={"%"}
                            />
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <h3 className="text-center mt-3">Water Analysis</h3>
                            <CustomDoughnut
                                labels={waterGraphLabels}
                                data={waterGraphValues}
                                descriptions={waterGraphDescriptions}
                                colors={['aqua', 'blue', 'yellow']}
                                unit={"%"}
                            />
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <h3 className="text-center mt-3">Fat vs Muscle</h3>
                            <CustomDoughnut
                                labels={['Muscle', 'Fat']}
                                data={[muscleFatGraph.skeletalMuscleMassPercentage.value, muscleFatGraph.bodyFatMassPercentage.value]}
                                descriptions={[muscleFatGraph.skeletalMuscleMassPercentage.description, muscleFatGraph.bodyFatMassPercentage.description]}
                                colors={['green', 'red']}
                                unit={"%"}
                            />
                        </div>
                    </div>
                </Card>
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <table className="table table-hover table-condensed">
                                <thead>
                                    <tr>
                                        <th>Metric</th>
                                        <th className="text-left">Lower</th>
                                        <th className="text-center">Result</th>
                                        <th className="text-right">Upper</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    content.dataWithParameters.map((v, idx) => (
                                        <React.Fragment key={idx}>
                                            <tr data-widget="expandable-table" aria-expanded={false}>
                                                <td>
                                                    {v.name}&nbsp;<span className="badge bg-info"><i className="fa fa-info"></i></span>
                                                </td>
                                                <td className="text-left" ><span className="badge bg-green" dangerouslySetInnerHTML={{__html: v.lower + " " + (v.unit === "None" ? "" : v.unit)}} /></td>
                                                <td className="text-center"><h1><span className="badge bg-primary" dangerouslySetInnerHTML={{__html: v.value + " " + (v.unit === "None" ? "" : v.unit)}} /></h1></td>
                                                <td className="text-right" ><span className="badge bg-red" dangerouslySetInnerHTML={{__html: v.upper + " " + (v.unit === "None" ? "" : v.unit)}} /></td>
                                            </tr>
                                            <tr className="expandable-body d-none">
                                                <td colSpan="4">
                                                    <p>
                                                        {v.description}
                                                    </p>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <table className="table table-hover table-condensed">
                                <thead>
                                    <tr>
                                        <th>Metric</th>
                                        <th className="text-center">Result</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    content.dataWithNoParameters.map((v, idx) => (
                                        <React.Fragment key={idx}>
                                            <tr data-widget="expandable-table" aria-expanded={false}>
                                                <td>
                                                    {v.name}&nbsp;<span className="badge bg-info"><i className="fa fa-info"></i></span>
                                                </td>
                                                <td className="text-center"><h1><span className="badge bg-primary" dangerouslySetInnerHTML={{__html: v.value + " " + (v.unit === "None" ? "" : v.unit)}}></span></h1></td>
                                            </tr>
                                            <tr className="expandable-body d-none">
                                                <td colSpan="4">
                                                    <p>
                                                        {v.description}
                                                    </p>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>  
            </>
        )
    }

    render() {
        console.log('asd', this.props.measurementData, this.props.params.id);
        return (
            <Main>
                {this.props.measurementData === undefined ?
                    <div className="text-center">
                        <Loading isLoading={true} />
                    </div>
                    :
                    this.state.failed ?
                        'Not found'
                        :
                        //this.props.measurementData[String(this.props.params.id)].forEach((v, k) => {
                        this.renderTable()
                    //})
                }
            </Main>
        );
    }
}

Scan.defaultProps = {
    measurementData: undefined,
};

const mapStateToProps = (state) => {
    return {
        measurementData: state.MeasurementStore.fullMeasurementData[state.MeasurementStore.fullFocusId]
    }
};

export default connect(mapStateToProps)(withRouter(Scan));