import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { getUserLoginMethodByUsername, getUserLoginTokenFromUserNamePassword, getUserExistingTokenToResumeSession } from '../Reducers/User';
import SingleBlock from '../Layouts/SingleBlock';
import Card from '../Components/Card';
import { FormValidation } from '../Helpers';
import Datetime from 'react-datetime';

class Register extends React.Component {
    constructor(props) {
        super(props); // {email, handleLoginSubmit}
        this.state = {
            inputUsername: undefined,
            inputUsernameError: undefined,

        }
    }

    // https://github.com/arqex/react-datetime
    render() {
        return (
            <SingleBlock big={true}>
                <Card border={false} color="transparent">
                    <p className="login-box-msg">Register your bodyCODEZ Account</p>
                    <div className="row">
                        <div className="mt-1 col-12">
                            <form id="loginForm" onSubmit={this.onSubmitLoginForm} className="form-horizontal">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label htmlFor="username" className="control-label">First Name</label>
                                        <input type="text" value={this.state.inputUsername} onChange={e => this.onUpdateUserName(e.target.value)} className="form-control" id="username" />
                                        { this.state.inputUsernameError ? <span className="has-error" id="usernameError">Please enter a valid e-mail address</span> : '' }
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="username" className="control-label">Last Name</label>
                                        <input type="text" value={this.state.inputUsername} onChange={e => this.onUpdateUserName(e.target.value)} className="form-control" id="username" />
                                        { this.state.inputUsernameError ? <span className="has-error" id="usernameError">Please enter a valid e-mail address</span> : '' }
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="username" className="control-label">Sex</label>
                                        <select className="form-control">
                                            <option value="female">Female</option>
                                            <option value="male">Male</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-8">
                                        <label htmlFor="username" className="control-label">Email Address</label>
                                        <input type="text" value={this.state.inputUsername} onChange={e => this.onUpdateUserName(e.target.value)} className="form-control" id="username" placeholder="Email" name="username" />
                                        { this.state.inputUsernameError ? <span className="has-error" id="usernameError">Please enter a valid e-mail address</span> : '' }
                                    </div>
                                    <div className="col-sm-4">
                                        <label htmlFor="username" className="control-label">Phone Number</label>
                                        <input type="text" value={this.state.inputUsername} onChange={e => this.onUpdateUserName(e.target.value)} className="form-control" id="username" placeholder="Email" name="username" />
                                        { this.state.inputUsernameError ? <span className="has-error" id="usernameError">Please enter a valid e-mail address</span> : '' }
                                    </div>
                                    <div className="col-sm-4">
                                        <label htmlFor="username" className="control-label">How did you hear about us?</label>
                                        <select className="form-control">
                                            <option value="Google/Search Engine">Google/Search Engine</option>
                                            <option value="I got here as a result of the Kickstart WhatsApp Bot">I got here as a result of the Kickstart WhatsApp Bot</option>
                                            <option value="My Personal Trainer suggested I do a scan">My Personal Trainer suggested I do a scan</option>
                                            <option value="I saw the device at the Gym">I saw the device at the Gym</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Card>
            </SingleBlock>
        );
    }
}

Register.defaultProps = {

};

const mapStateToProps = ({UserStore}) => {
    return {

    }
};

export default connect(
    mapStateToProps
)(Register);