import { put, takeLatest, call, select } from 'redux-saga/effects';
import { API } from '../Helpers';
import { selectUserToken } from '../Reducers/User';

function* getUserLoginMethodByUsername(action) {
    const res = yield call([API, 'get'], ['user/provider?username=' + action.username]);
    let sso = false;
    if(res.success === true) {
       sso = res.name !== "web";
    }
    yield put({type: 'USER_SET_LOGIN_METHOD', sso: sso, username: action.username})
}

function* getUserPasswordReset(action) {
    yield call([API, 'patch'], 'user/resetpassword', {username: action.username});
    yield put({type: 'USER_SET_PASSWORD_RESET_SUCCESS'})
}

function* getUserTokenFromUsernamePassword(action) {
    const res = yield call([API, 'post'], 'login', {username: action.username, password: action.password});
    let token = undefined;
    if(res.success) {
        token = res.token;
        yield put({type: 'USER_SET_LOGIN_TOKEN', token})
    } else {
        yield put({type: 'USER_SET_LOGIN_FAILED', description: res.description})
    }
}

function* getUserTokenFromBrowser(action) {
    try {
        const token = localStorage.getItem("token");
        if(token !== undefined || token.length > 0) {
            const isLoggedIn = yield call([API, 'get'], 'user/heartbeat', token);
            if(isLoggedIn.success) {
                yield put({type: 'USER_SET_LOGIN_TOKEN', token});
            } else {
                throw new Error("Valid Token, Not Logged In");
            }
        } else {
            throw new Error("Invalid Token");
        }
    } catch(err) {
        console.log('Login Error', err);
        yield put({type: 'USER_LOGOUT'});
    }
}

/* eslint-disable */ 
function* storeUserTokenInBrowser(action) {
    localStorage.setItem("token", action.token);
}
/* eslint-enable */ 

function* removeUserTokenFromBrowser() {
    localStorage.removeItem("token");
    yield put({
        type: 'USER_RESET_STATE'
    })
}

function* updateUserProfile(action) {
    const token = yield select(selectUserToken);
    let inputObject = {};
    if(action.firstname) {
        inputObject['firstname'] = action.firstname;
    }
    if(action.lastname) {
        inputObject['lastname'] = action.lastname;
    }
    if(action.sex) {
        inputObject['gender'] = action.sex;
    }
    if(action.birthday) {
        inputObject['birthday'] = action.birthday;
    }
    if(action.mobile) {
        inputObject['mobile'] = action.mobile;
    }
    if(action.zaidnumber) {
        yield call(setUserZaIdNumber, { zaidnumber: action.zaidnumber});
    }
    const res = yield call([API, 'patch'], 'users', inputObject, token);
    yield put({type: 'USER_STORE_PROFILE', ...res.user});
}

function* setUserZaIdNumber(action) {
    console.log('set user za id');
    const token = yield select(selectUserToken);
    const res = yield call([API, 'patch'], 'update_identity', {idnumber: action.zaidnumber}, token);
    if(!res.success) {
        yield put({
            type: 'USER_ZA_ID_NUMBER_ERROR',
            description: 'Invalid ID Number specified'
        })
    }
}

function* getUserProfileInfo() {
    const token = yield select(selectUserToken);
    const res = yield call([API, 'get'], 'user/info', token);
    yield put({type: 'USER_STORE_PROFILE', ...res.data})
}

function* sendContactUsEmail(action) {
    const token = yield select(selectUserToken);
    const { name, email, phone, message } = action;
    yield call([API, 'post'], 'send', {name, email, phone, message}, token);
}

export default function* userSaga() {
    yield takeLatest('USER_FETCH_LOGIN_METHOD_BY_USERNAME', getUserLoginMethodByUsername);
    yield takeLatest('USER_FETCH_PASSWORD_RESET_BY_USERNAME', getUserPasswordReset);
    yield takeLatest('USER_FETCH_TOKEN_BY_USERNAME_PASSWORD', getUserTokenFromUsernamePassword);
    yield takeLatest('USER_SET_LOGIN_TOKEN', storeUserTokenInBrowser);
    yield takeLatest('USER_FETCH_TOKEN_FROM_BROWSER', getUserTokenFromBrowser);
    yield takeLatest('USER_LOGOUT', removeUserTokenFromBrowser);
    yield takeLatest('USER_FETCH_PROFILE_INFO', getUserProfileInfo);
    yield takeLatest('USER_SET_LOGIN_TOKEN', getUserProfileInfo);
    yield takeLatest('USER_SEND_CONTACT_EMAIL', sendContactUsEmail);
    yield takeLatest('USER_UPDATE_PROFILE', updateUserProfile);
}