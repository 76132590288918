import moment from 'moment';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { API } from '../Helpers';
import { selectUserToken } from '../Reducers/User';

function* getLatestMeasurementForDashboard() {
    const token = yield select(selectUserToken);
    const res = yield call([API, 'get'], 'measurement/latest', token);
    if(res.success) {
        const data = res.data;
        yield put({type: 'MEASUREMENT_SET_LATEST_FOR_DASHBOARD', data})
    }
}

function* getMeasurementsForApproval() {
    const token = yield select(selectUserToken);
    const res = yield call([API, 'get'], 'measurement/approvals', token);
    if(res.success) {
        let data = {};
        res.data.forEach((v) => {
            data[String(v.id)] = {
                ...v,
                isLoading: false
            }
        });
        
        yield put({type: 'MEASUREMENT_SET_LISTS_FOR_APPROVAL', data })
    }
}

function* setMeasurementApprovalStatus({measurementId, approve, reason}) {
    const token = yield select(selectUserToken);
    const res = yield call([API, 'patch'], 'measurement/approvalStatus/' + measurementId, { isApproved: approve, reason }, token);
    if(res.success) {
        yield put({type: 'MEASUREMENT_APPROVAL_REMOVE_BY_ID', measurementId: measurementId})
    }
}

function* getMeasurementCountsByDay({startDate, endDate}) {
    const token = yield select(selectUserToken);
    const res = yield call([API, 'get'], 'measurement/count/' + startDate + '/' + endDate, token);
    let resObject = {};
    
    res.data.forEach((val) => {
        if(val.year in resObject) {
            resObject[val.year][val.month][val.day] = val.count;
        } else {
            resObject[val.year] = {
                [val.month]: {
                    [val.day]: val.count
                }
            };
        }
    })
    if(res.success) {
        yield put({type: 'MEASUREMENT_SET_COUNT_BY_DAY', measurementCountByDay: resObject})
    }
}

function* getMeasurementSummaryByDate({date}) {
    const token = yield select(selectUserToken);
    const res = yield call([API, 'get'], 'measurement/summary/' + date, token);
    let measurements = {};
    res.data.forEach((value) => {
        const date = moment(value.timestamp).format('YYYY-MM-DD');
        measurements = {
            ...measurements,
            [date]: date in measurements ? [
                ...measurements[date],
                value
            ] : [value]
        }
    });
    if(res.success) {
        yield put({
            type: 'MEASUREMENT_SET_SUMMARY', 
            measurementSummary: measurements
        })
    }
    
}

function* getFullMeasurementById({id}) {
    const token = yield select(selectUserToken);
    const res = yield call([API, 'get'], 'measurement/full/' + id, token);
    if(res.success) {
        const {timestamp} = res;

        let weightGraph = {
            totalBodyWater: undefined,
            bodyFatPercent: undefined,
            skeleton: undefined,
            protein: undefined,
            bodyFatMass: undefined,
            minerals: undefined,
        };

        let waterGraph = {
            totalBodyWater: undefined,
            icw: undefined,
            ecw: undefined,
            other: undefined
        }

        let muscleFatGraph = {
            skeletalMuscleMass: undefined,
            bodyFatMass: undefined,
            weight: undefined
        };

        let headingData = {
            weight: undefined,
            age: undefined,
            bmi: undefined,
            fitnessScore: undefined
        };

        let dataWithParameters = [];
        let dataWithNoParameters = [];
        // weight = bodycellmass + bodycellmass + skeletalmass + totalbodywater + bodyfatmass

        res.data.forEach((v) => {
            v.value = parseFloat(v.value);
            switch(v.name.toLowerCase().trim()) {
                case 'percent body fat':
                    weightGraph.bodyFatPercent = v;
                    dataWithParameters.push(v);
                    break;
                case 'total body water':
                    weightGraph.totalBodyWater = v;
                    waterGraph.totalBodyWater = v;
                    dataWithParameters.push(v);
                    break;
                case 'protein':
                    weightGraph.protein = v;
                    dataWithParameters.push(v);
                    break;
                case 'minerals':
                    weightGraph.minerals = v;
                    dataWithParameters.push(v);
                    break;
                case 'body fat mass':
                    dataWithParameters.push(v);
                    weightGraph.bodyFatMass = v;
                    muscleFatGraph.bodyFatMass = v;
                    break;
                case 'weight (kg)':
                    headingData.weight = v;
                    muscleFatGraph.weight = v;
                    break;
                case 'intracellular water (icw)':
                    waterGraph.icw = v;
                    dataWithParameters.push(v);
                    break
                case 'extracellular water (ecw)':
                    waterGraph.ecw = v;
                    dataWithParameters.push(v);
                    break
                case 'skeletal muscle mass':
                    muscleFatGraph.skeletalMuscleMass = v;
                    dataWithParameters.push(v);
                    break;
                case 'bmi':
                    headingData.bmi = v;
                    dataWithParameters.push(v);
                    break;
                case 'fitness score':
                    headingData.fitnessScore = v;
                    break;
                case 'age':
                    headingData.age = v;
                    break;
                default:
                    if(v.upper !== 'None') {
                        dataWithParameters.push(v);
                    } else {
                        dataWithNoParameters.push(v);
                    }
                    break;
                    
            }
        });
        // date = moment(value.timestamp).format('YYYY-MM-DD');
        console.log('incoming', waterGraph);
        const { 
            totalBodyWater, 
            bodyFatPercent,
            bodyFatMass,
            minerals, 
            protein
        } = weightGraph;
        
        const totalWeight = totalBodyWater.value + bodyFatMass.value + minerals.value + protein.value;
        weightGraph = {
            totalBodyWaterPercentage: { value: totalBodyWater.value/totalWeight*100, description: totalBodyWater.description },
            bodyFatMassPercentage: { value: bodyFatPercent.value, description: bodyFatPercent.description },
            mineralsPercentage: { value: minerals.value/totalWeight*100, description: minerals.description },
            proteinPercentage: { value: protein.value/totalWeight*100, description: protein.description }
        }


        const totalWater = waterGraph.totalBodyWater.value;
        waterGraph = {
            icwPercentage: { value: waterGraph.icw.value/totalWater*100, description: waterGraph.icw.description },
            ecwPercentage: { value: waterGraph.ecw.value/totalWater*100, description: waterGraph.ecw.description },
            otherPercentage: { value: (totalWater-(waterGraph.icw.value+waterGraph.ecw.value))/totalWater*100, description: 'Other' }
        }

        const totalActualWeight = muscleFatGraph.skeletalMuscleMass.value+muscleFatGraph.bodyFatMass.value;
        muscleFatGraph = {
            skeletalMuscleMassPercentage: { value: muscleFatGraph.skeletalMuscleMass.value/totalActualWeight*100, description: muscleFatGraph.skeletalMuscleMass.description },
            bodyFatMassPercentage: { value: muscleFatGraph.bodyFatMass.value/totalActualWeight*100, description: muscleFatGraph.bodyFatMass.description },
            // otherPercentage: { value: (totalActualWeight-(muscleFatGraph.skeletalMuscleMass.value+muscleFatGraph.bodyFatMass.value))/totalActualWeight*100, description: 'Your skeleton and muscles that are unaffected by fitness'}
        }

        yield put({
            type: 'MEASUREMENT_SET_FULL_FOR_ID',
            id: id,
            data: {
                header: {
                    ...headingData,
                    weightGraph,
                    waterGraph,
                    muscleFatGraph,
                },
                content: {
                    dataWithNoParameters,
                    dataWithParameters
                },
                timestamp
            }
        })
    }
} 

export default function* measurementSaga() {
    yield takeLatest('MEASUREMENT_FETCH_LATEST_FOR_DASHBOARD', getLatestMeasurementForDashboard);
    yield takeLatest('MEASUREMENT_FETCH_APPROVAL_LIST', getMeasurementsForApproval); // we also get the approval list
    yield takeLatest('MEASUREMENT_SET_APPROVAL_STATUS', setMeasurementApprovalStatus);
    yield takeLatest('MEASUREMENT_GET_COUNTS_BY_DAY', getMeasurementCountsByDay);
    yield takeLatest('MEASUREMENT_GET_SUMMARY_BY_DATE', getMeasurementSummaryByDate);
    yield takeLatest('MEASUREMENT_GET_FULL_BY_ID', getFullMeasurementById)
} 