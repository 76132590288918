
// STATE MACHINE
const initialState = {
    dashboardData: [],
    measurementsPendingApproval: {},
    measurementCountByDay: {},
    measurementSummaryByDay: {},
    fullMeasurementData: {},
    fullFocusId: 0,
    isLoadingMeasurementCountByDay: false
}

const MeasurementStore = (state = initialState, action) => {
    switch (action.type) {
        case 'MEASUREMENT_FETCH_LATEST_FOR_DASHBOARD':
            return {
                ...state,             
            }
        case 'MEASUREMENT_SET_LATEST_FOR_DASHBOARD':
            return {
                ...state,
                dashboardData: action.data,                
            }
        case 'MEASUREMENT_SET_LISTS_FOR_APPROVAL':
            return {
                ...state,
                measurementsPendingApproval: action.data 
            }
        case 'MEASUREMENT_APPROVAL_REMOVE_BY_ID':
            const { measurementsPendingApproval } = state;
            let newMeasurementsPendingApproval = Object.assign([], measurementsPendingApproval); // create new array
            delete newMeasurementsPendingApproval[action.measurementId];
            return {
                ...state,
                measurementsPendingApproval: newMeasurementsPendingApproval
            }
        case 'MEASUREMENT_SET_APPROVAL_STATUS':
            return {
                ...state,
                measurementsPendingApproval: {
                    ...state.measurementsPendingApproval,
                    [action.measurementId]: {
                        ...state.measurementsPendingApproval[action.measurementId],
                        isLoading: true
                    }
                }
            }
        case 'MEASUREMENT_SET_COUNT_BY_DAY':
            const { measurementCountByDay } = action;
            return {
                ...state,
                measurementCountByDay,
                isLoadingMeasurementCountByDay: false
            }
        case 'MEASUREMENT_GET_COUNTS_BY_DAY':
            return {
                ...state,
                isLoadingMeasurementCountByDay: true
            }
        case 'MEASUREMENT_SET_SUMMARY':
            return {
                ...state,
                measurementSummaryByDay: {
                    ...state.measurementSummaryByDay,
                    ...action.measurementSummary,
                },
                isLoadingMeasurementCountByDay: false
            }
        case 'MEASUREMENT_GET_SUMMARY_BY_DATE':
            return {
                ...state,
                measurementSummaryByDay: action.measurementSummary,
                isLoadingMeasurementCountByDay: true
            }
        case 'MEASUREMENT_SET_FULL_FOR_ID':
            const {id, data} = action;
            return {
                ...state,
                fullMeasurementData: {
                    ...state.fullMeasurementData,
                    [id]: data
                },
            }
        case 'MEASUREMENT_GET_FULL_BY_ID':
            return {
                ...state,
                fullFocusId: action.id
            }
        default:
            return state;
    }
}

// SELECTORS

// SAGA REFERENCE
export const getLatestMeasurementFromDashboard = () => {
    return {
        type: 'MEASUREMENT_FETCH_LATEST_FOR_DASHBOARD'
    }
}

export const setMeasurementApprovalStatus = (measurementId, approve, reason = undefined) => {
    return {
        type: 'MEASUREMENT_SET_APPROVAL_STATUS',
        measurementId,
        approve,
        reason
    }
}

export const getMeasurementApprovalList = () => {
    return {
        type: 'MEASUREMENT_FETCH_APPROVAL_LIST'
    }
}

export const getMeasurementCountsByDay = (startDate, endDate) => {
    return {
        type: 'MEASUREMENT_GET_COUNTS_BY_DAY',
        startDate,
        endDate
    }
}

export const getMeasurementSummaryByDate = (date) => {
    return {
        type: 'MEASUREMENT_GET_SUMMARY_BY_DATE',
        date
    }
}

export const getSpecificMeasurementById = (id) => {
    return {
        type: 'MEASUREMENT_GET_FULL_BY_ID',
        id
    }
}

export default MeasurementStore;