import React from 'react';
import StatsCard from '../Components/StatsCard';
import { setMeasurementApprovalStatus } from '../Reducers/Measurement';
import Loading from '../Components/Loading';
import moment from 'moment';

class ApprovalCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isDeclining: false, declineReason: '' };
    }
    
    declineMeasurement() {
        this.setState({
            isDeclining: true,
        }, () => {
            this.declineInput.focus();
        });
    }

    handleUpdateDeclineReason(val) {
        this.setState({
            declineReason: val
        })
    }

    handleDeclineFormSubmit(e, id) {
        e.preventDefault();
        const declineReason = this.state.declineReason.length > 0 ? this.state.declineReason : 'SKIPPED';
        this.props.dispatch(setMeasurementApprovalStatus(id, false, declineReason))
    }

    render() {
        const m = this.props.data;
        return (
        <div className="card bg-info">
            { m.isLoading ? 
            <Loading isLoading={true}/>
            : '' }
            <div className="card-body">
                <h3 className="card-title text-center">{moment(m.timestamp).fromNow()}</h3>
                <div className="card-text mt-5">
                    <div className="row">
                        <StatsCard color="light" name="Weight" value={m.weight + 'kg'} icon={<i className="fas fa-weight"></i>} />
                        <StatsCard color="light" name="Target Weight" value={m.target_weight + 'kg'} icon={<i className="fas fa-bullseye"></i>} />
                        <StatsCard color="light" name="BMI" value={m.bmi} icon={<i className="fas fa-calculator"></i>} />
                        <StatsCard color="light" name="Fitness Score" value={m.fitness_score + '/100'} icon={<i className="fas fa-percent"></i>} />
                    </div>
                </div>
                {
                this.state.isDeclining ?
                    <form onSubmit={(e) => this.handleDeclineFormSubmit(e, m.id)}>
                        <div className="row">
                            <div className="col-md-12 col-lg-4 offset-lg-4 text-center">
                                <label>
                                    <p>
                                        Why are you declining this scan?
                                    </p>
                                    <textarea className="form-control" aria-label="With textarea" onChange={(e) => this.handleUpdateDeclineReason(e.target.value)} value={this.state.declineReason} ref={(input) => this.declineInput = input} />
                                    <p className="smalltext">
                                        * We will use this information for research to ensure our devices are as accurate as possible.
                                    </p>
                                </label>
                                <button type="submit" className="btn btn-app btn-success">Submit</button>
                                <button type="submit" className="btn btn-app btn-warning">Skip</button>
                            </div>
                        </div>
                    </form>
                    :
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p>Please approve or decline this scan</p>
                            <button type="button" className="btn btn-app btn-success" onClick={() => this.props.dispatch(setMeasurementApprovalStatus(m.id, true))}><i className="fa fa-check"></i></button>
                            <button type="button" className="btn btn-app btn-warning" onClick={() => this.declineMeasurement()}><i className="fa fa-times"></i></button>
                        </div>
                    </div>
                }
            </div>
        </div>
        )
    }
}

ApprovalCard.defaultProps = {
    pendingApprovals: {}
};

export default ApprovalCard;