import { Link } from 'react-router-dom';

function SideMenu() {
  return (
    <>
    <Link to="/dashboard" className="brand-link" style={{ textAlign: 'center', borderBottom: '1px solid #bbbbbb' }}>
        <img src="/static/assets/Full_Light-svg.svg" style={{ height: '24px' }} alt="Logo White"/>
    </Link>

    <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex" style={{ borderBottom: '0' }}>
            {/* <div className="info" style={{ borderBottom: '1px solid #bbbbbb' }}>
                <Link to="/profile" className="d-block" style={{ color: '#ffffff' }}>
                    {% if user.firstname is not none %}
                    User:
                    <b>{{ user.firstname }}</b>
                    {%endif%}
                    {% if user.lastname is not none %}
                    <b>{{ user.lastname }}</b>
                    <br>
                    {%endif%}
                    {% if user.birthday is not none %}
                    Birthday:
                    <b>{{ user.birthday }}</b>
                    <br>
                    {%endif%}
                    Email: <b>{{ user.email }}</b><br>
                    {% if user.mobile is not none %}
                    Mobile:
                    <b>{{ user.mobile }}</b>
                    <br>
                    {%endif%}
                </Link>
            </div> */}
        </div>
        <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <li className="nav-item">
                <Link to="/dashboard" className="nav-link">
                    <i className="nav-icon fas fa-home"></i>
                    <p style={{ color: '#ffffff' }}>Dashboard</p>
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/history" className="nav-link">
                    <i className="nav-icon fa fa-clock"></i>
                    <p style={{ color: '#ffffff' }}>History</p>
                </Link>
            </li>
            {/* <li className="nav-item">
                <Link to="/graphs" className="nav-link">
                <i className="nav-icon fas fa-chart-line"></i>
                <p style={{ color: '#ffffff' }}>Graphs &amp; Stats</p>
                </Link>
            </li> */}
            <li className="nav-item">
                <Link to="/profile" className="nav-link">
                <i className="nav-icon fa fa-user"></i>
                <p style={{ color: '#ffffff' }}>My Profile</p>
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/contactus" className="nav-link">
                    <i className="nav-icon fa fa-phone"></i>
                    <p style={{ color: '#ffffff' }}>Contact Us</p>
                </Link>
            </li>
            <li className="nav-item">
                <Link to="/logout" className="nav-link">
                    <i className="nav-icon fa fa-lock"></i>
                    <p style={{ color: '#ffffff' }}>Logout</p>
                </Link>
            </li>
            </ul>
        </nav>
    </div>
    </>
  );
}

export default SideMenu;
