const Loading = ({isLoading, isSuccess}) => {
    let addedClassName = !isLoading && !isSuccess ? 'd-none' : '';
    return (
        <div className={'overlay ' + addedClassName}>
            {isSuccess ?
                <i className="fa fa-check text-green"></i>
            :
                <i className="fas fa-2x fa-sync-alt fa-spin"></i>
            }
        </div>
    )
}

Loading.defaultProps = {
    isLoading: false,
    isSuccess: false
}
    
export default Loading; 