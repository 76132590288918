import React from 'react';
import moment from 'moment';
import Main from '../Layouts/Main';
import { connect } from 'react-redux';
import { getMeasurementCountsByDay, getMeasurementSummaryByDate } from '../Reducers/Measurement';
import Card from '../Components/Card';
import { withNavigate } from '../Helpers';


class Calendar extends React.Component {
    constructor(props) {
        super(props);

        // moment().setDefault('Africa/Johannesburg')
        const month = moment();
        this.state = {
            month,
            selected: month,
            firstDay: month.clone().startOf("month").add("w" - 1).day("Sunday")
        };

        this.previous = this.previous.bind(this);
        this.next = this.next.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getMeasurementCountsByDay(this.state.firstDay.format('YYYY-MM-DD'), this.state.selected.clone().add('24', 'h').format('YYYY-MM-DD')));
        this.props.dispatch(
            getMeasurementSummaryByDate(
                this.state.selected.format('YYYY-MM-DD')
            )
        );
    }

    previous() {
        const {
            month,
        } = this.state;

        this.setState({
            month: month.subtract(1, 'month'),
            firstDay: month.clone().startOf("month").add("w" - 1).day("Sunday")
        }, () => {
            this.props.dispatch(
                getMeasurementCountsByDay(
                    this.state.firstDay.clone().format('YYYY-MM-DD'),
                    this.state.firstDay.clone().add(4, 'w').endOf('month').day('Saturday').format('YYYY-MM-DD'),
                )
            );
        });
    }

    next() {
        const {
            month,
        } = this.state;

        this.setState({
            month: month.add(1, 'month'),
            firstDay: month.clone().startOf("month").add("w" - 1).day("Sunday")
        }, () => {
            this.props.dispatch(
                getMeasurementCountsByDay(
                    this.state.firstDay.clone().format('YYYY-MM-DD'),
                    this.state.firstDay.clone().add(4, 'w').endOf('month').day('Saturday').format('YYYY-MM-DD'),
                )
            );
        });
    }

    select(day) {
        const lastMonth = this.state.month.clone().format('M');

        this.setState({
            selected: day.date,
            month: day.date.clone(),
            firstDay: day.date.clone().startOf("month").add("w" - 1).day("Sunday")
        }, () => {
            if (lastMonth !== this.state.month.format('M')) { // Only check for new results if the month changes
                this.props.dispatch(
                    getMeasurementCountsByDay(
                        this.state.firstDay.clone().format('YYYY-MM-DD'),
                        this.state.firstDay.clone().add(4, 'w').endOf('month').day('Saturday').format('YYYY-MM-DD'),
                    )
                );
            } else {
                this.props.dispatch(
                    getMeasurementSummaryByDate(
                        this.state.selected.format('YYYY-MM-DD')
                    )
                );
            }
        });
    }

    renderWeeks() {
        let weeks = [];
        let done = false;
        let date = this.state.month.clone().startOf("month").add("w" - 1).day("Sunday");
        let count = 0;
        let monthIndex = date.month();

        const {
            selected,
            month,
        } = this.state;

        const { measurementsWithCount } = this.props;

        while (!done) {
            const yearStr = String(month.format('Y'));
            let dayDataCount = {};
            if (yearStr in measurementsWithCount) {
                const monthStr = String(month.format('M'));
                if (monthStr in measurementsWithCount[yearStr]) {
                    dayDataCount = Object.assign({}, measurementsWithCount[yearStr][monthStr]);
                }
            }
            weeks.push(
                <Week key={count}
                    date={date.clone()}
                    month={month}
                    select={(day) => this.select(day)}
                    selected={selected}
                    dayDataCount={dayDataCount}
                />
            );

            date.add(1, "w");

            done = count++ > 2 && monthIndex !== date.month();
            monthIndex = date.month();
        }

        return weeks;
    };

    renderMonthLabel() {
        const {
            month,
        } = this.state;

        return <span className="month-label card-title">{month.format("MMMM YYYY")}</span>;
    }

    redirect(page) {

    }

    render() {
        const selectedSummaryKey = this.state.selected.format('YYYY-MM-DD');
        console.log('selected summary key', String(selectedSummaryKey), this.props.measurementSummary);
        const measurementSummaryKeys = String(selectedSummaryKey) in this.props.measurementSummary ? this.props.measurementSummary[selectedSummaryKey] : [];
        console.log('h', measurementSummaryKeys);
        return (
            <Main>
                <Card color="light" isLoading={this.props.isLoading}>
                    <div className="row">
                        <div className="col-md-6">
                            <section className="calendar rounded-lg">
                                <header className="header">
                                    <div className="month-display calendar-row bg-info">
                                        <i className="arrow fa fa-angle-left" onClick={this.previous} />
                                        {this.renderMonthLabel()}
                                        <i className="arrow fa fa-angle-right" onClick={this.next} />
                                    </div>
                                    <DayNames />
                                </header>
                                {this.renderWeeks()}
                            </section>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header bg-info">
                                    <h3 className="card-title text-uppercase text-center">{this.state.selected.format('dddd [the] Do')}</h3>
                                </div>
                                <div className="card-body p-0">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Time</th>
                                                <th>Location</th>
                                                <th>Summary</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                measurementSummaryKeys.length > 0 ?
                                                    measurementSummaryKeys.map((arr, idx) => (
                                                        <tr className="hover-cursor" key={idx} onClick={() => this.props.navigate("/history/" + arr.id)}>
                                                            <td>{moment(arr.timestamp).format('HH:mm')}</td>
                                                            <td>{arr.location ? arr.location : 'N/A'}</td>
                                                            <td><span className="badge badge-success">Weight: {arr.weight}</span> <span className="badge badge-success">Fitness Score: {arr.fitness_score}</span></td>
                                                        </tr>
                                                    ))
                                                    :
                                                    this.props.isLoading ?
                                                        (
                                                            <tr>
                                                                <td colSpan="3">-</td>
                                                            </tr>
                                                        )
                                                        :
                                                        (
                                                            <tr>
                                                                <td colSpan="3">There are no records for this day</td>
                                                            </tr>
                                                        )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </Main>
        );
    }
}

class DayNames extends React.Component {
    render() {
        return (
            <div className="calendar-row day-names">
                <span className="day">Sun</span>
                <span className="day">Mon</span>
                <span className="day">Tue</span>
                <span className="day">Wed</span>
                <span className="day">Thu</span>
                <span className="day">Fri</span>
                <span className="day">Sat</span>
            </div>
        );
    }
}

class Week extends React.Component {
    render() {
        let days = [];
        let {
            date
        } = this.props;

        const {
            dayDataCount,
            month,
            selected,
            select,
        } = this.props;
        const today = moment();
        for (var i = 0; i < 7; i++) {
            let day = {
                name: date.format("dd").substring(0, 1),
                number: date.date(),
                isCurrentMonth: date.month() === month.month(),
                isToday: date.isSame(new Date(), "day"),
                date: date
            };
            const dayStr = String(date.format('D'));
            let dataCount = 0;
            if (dayStr in dayDataCount) {
                dataCount = dayDataCount[dayStr];
            }
            const isFuture = today.diff(day.date) < 0;
            days.push(
                <Day
                    key={i}
                    day={day}
                    selected={selected}
                    select={select}
                    dataCount={dataCount}
                    isFuture={isFuture}
                />
            );

            date = date.clone();
            date.add(1, "day");
        }

        return (
            <div className="calendar-row week" key={days[0]}>
                {days}
            </div>
        );
    }

}

class Day extends React.Component {
    render() {
        const {
            day,
            day: {
                date,
                isCurrentMonth,
                isToday,
                number
            },
            dataCount,
            select,
            selected,
            isFuture   
        } = this.props;
        return (
            <span
                key={date.toString()}
                className={
                    "day"
                    + (isToday ? " today" : "")
                    + (isCurrentMonth ? "" : " different-month")
                    + (date.isSame(selected) ? " selected" : "")
                    + (isFuture ? " future-date" : "")
                }
                onClick={isFuture ? undefined : () => select(day)}>
                {number}
                {
                    dataCount ? <span className="data-count btn btn-md btn-sm bg-info rounded-circle">{dataCount}</span> : ''
                }
            </span>
        );
    }
}

Calendar.defaultProps = {
    measurementsWithCount: {},
    measurementSummary: {},
    isLoading: true
};

const mapStateToProps = state => ({
    measurementsWithCount: state.MeasurementStore.measurementCountByDay,
    measurementSummary: state.MeasurementStore.measurementSummaryByDay,
    isLoading: state.MeasurementStore.isLoadingMeasurementCountByDay
});

export default connect(mapStateToProps)(withNavigate(Calendar));