import React from 'react';
import { connect } from 'react-redux';
import Main from '../Layouts/Main';
import Card from '../Components/Card';
import { sendUserContactEmail } from '../Reducers/User';

class ContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
        }
        this.handleSubmitContactForm = this.handleSubmitContactForm.bind(this);
    }

    handleUpdateMessageBox(val) {
        console.log('new val', val);
        this.setState({
            message: val
        })
    }

    handleSubmitContactForm(e) {
        e.preventDefault();
        console.log('submitting');
        console.log(this.props);
        this.props.dispatch(sendUserContactEmail(this.props.name, this.props.email, this.props.phone, this.state.message));
    }


    render() {
        return (
            <Main>
                <div class="row">
                    <div class="col-md-4">
                        <div class="info-box hover-cursor" onClick={() => window.location.href = 'https://wa.me/+27678401266'}>
                            <span class="info-box-icon bg-success"><i class="fab fa-whatsapp"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Whatsapp Us</span>
                                <span class="info-box-number">+27678401266</span>
                            </div>
                        </div>
                        <div class="info-box hover-cursor" onClick={() => window.location.href = 'mailto:hello@bodycodez.com'}>
                            <span class="info-box-icon bg-primary"><i class="fa fa-envelope"></i></span>
                            <div class="info-box-content">
                                <span class="info-box-text">Email Us</span>
                                <span class="info-box-number">hello@bodycodez.com</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <Card color="secondary" isLoading={this.props.name === ''}>
                            <form onSubmit={this.handleSubmitContactForm} style={{maxWidth: '600px', margin: 'auto'}}>
                                <h5>Get in touch below</h5>
                                <div class="form-group">
                                    <label class="col-form-label" for="inputSuccess">Name</label>
                                    <input type="text" class="form-control" value={this.props.name} readOnly/>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                        <label class="col-form-label" for="inputSuccess">Email Address</label>
                                        <input type="text" class="form-control" value={this.props.email} readOnly/>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label class="col-form-label" for="inputSuccess">Phone Number</label>
                                            <input type="text" class="form-control" value={this.props.phone} readOnly/>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Message</label>
                                    <textarea class="form-control" rows="3" placeholder="Message" onChange={(e) => this.handleUpdateMessageBox(e.target.value)}></textarea>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-info">Submit</button>
                                </div>
                            </form>
                        </Card>
                    </div>
                </div>
            </Main>
        );
    }
}

ContactUs.defaultProps = {
    name: undefined,
    email: undefined,
    phone: undefined
};

const mapStateToProps = state => ({
    name: state.UserStore.firstname,
    email: state.UserStore.email,
    phone: state.UserStore.phone
});

export default connect(
    mapStateToProps
)(ContactUs);