import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { doUserLogout } from '../Reducers/User';
import Loading from '../Components/Loading';
import SingleBlock from '../Layouts/SingleBlock';
import Card from '../Components/Card';

const Logout = ({dispatch, loginToken}) => {
    dispatch(doUserLogout());
    return (
        <SingleBlock>
            <Card>
                <Loading isLoading={loginToken.length > 1} isSuccess={loginToken.length < 1} />
                <h5 className="text-center">Successfully Logged Out</h5>
            </Card>
            {
                loginToken.length < 1 ? <Navigate to="/login"></Navigate> : ''
            }
        </SingleBlock>
    );
}

Logout.defaultProps = {
    loginToken: ''
};

const mapStateToProps = ({UserStore}) => {
    return {
        loginToken: UserStore.token,
    }
};

export default connect(mapStateToProps)(Logout);