import React from 'react';
import { connect } from 'react-redux';
import FeedCard from '../Components/FeedCard';
import Loading from '../Components/Loading';

const ApprovalFeed = (props) => {
    const pendingApprovalKeys = props.pendingApprovals !== undefined ? Object.keys(props.pendingApprovals) : undefined
    return (
        <>
        {
            pendingApprovalKeys !== undefined 
            ?
                pendingApprovalKeys.length > 0 ?
                    pendingApprovalKeys.map((index) => {
                        const m = props.pendingApprovals[index];
                        return (
                            <FeedCard 
                                key={index}
                                data={m}
                                dispatch={props.dispatch}
                            />
                        )
                    })
                : ''
            :
                <Loading isLoading={true} />
        }
        </>
    )
}

ApprovalFeed.defaultProps = {
    pendingApprovals: undefined
};

const mapStateToProps = state => ({
    pendingApprovals: state.MeasurementStore.measurementsPendingApproval
});

export default connect(
    mapStateToProps
)(ApprovalFeed);