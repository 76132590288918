
// STATE MACHINE
const initialState = {
    token: '',
    username: '',
    birthday: '',
    email: '',
    firstname: '',
    gender: '',
    idnumber: '',
    lastname: '',
    mobile: '',
    zaidnumber: '',
    zaidnumberErrorMsg: '',
    isSSO: undefined,
    passwordResetSuccess: false,
    isLoadingUserRequest: false,
}

const UserStore = (state = initialState, action) => {
    switch (action.type) {
        case 'USER_FETCH_TOKEN_BY_USERNAME_PASSWORD':
            return {
                ...state,
                isLoadingUserRequest: true                
            }
        case 'USER_SET_LOGIN_TOKEN':
            return {
                ...state,
                token: action.token,
                isLoadingUserRequest: false    
            };
        case 'USER_SET_LOGIN_FAILED':
            return {
                ...state,
                loginFailedMessage: action.description,
                isLoadingUserRequest: false    
            };
        case 'USER_STORE_LOGOUT':
            return {
                ...state,
                token: null,
            };
        case 'USER_FETCH_LOGIN_METHOD_BY_USERNAME': 
            return {
                ...state,
                isLoadingUserRequest: true
            };
        case 'USER_SET_LOGIN_METHOD':
            const {sso, username} = action;
            return {
                ...state,
                isSSO: sso,
                username: username,
                isLoadingUserRequest: false
            };
        case 'USER_FETCH_PASSWORD_RESET_BY_USERNAME':
            return {
                ...state,
                isLoadingUserRequest: true
            };
        case 'USER_SET_PASSWORD_RESET_SUCCESS':
            return {
                ...state,
                passwordResetSuccess: true,
                isLoadingUserRequest: false
            };
        case 'USER_FETCH_TOKEN_FROM_BROWSER':
            return {
                ...state,
                isLoadingUserRequest: true
            }
        case 'USER_RESET_STATE':
            return {
                ...state,
                ...initialState
            }
        case 'USER_FETCH_PROFILE_INFO':
            return {
                ...state,
                isLoadingUserRequest: true
            }
        case 'USER_STORE_PROFILE':
            const { birthday, email, firstname, gender, idnumber, lastname, mobile, zaidnumber } = action;
            return {
                ...state,
                isLoadingUserRequest: false,
                birthday: birthday !== null ? birthday : state.birthday, 
                email: email !== null ? email : state.email,
                firstname: firstname !== null ? firstname : state.firstname,
                gender: gender !== null ? gender : state.gender,
                idnumber: idnumber !== null ? idnumber : state.idnumber,
                lastname: lastname !== null ? lastname : state.lastname,
                mobile: mobile !== null ? mobile : state.mobile,
                zaidnumber: zaidnumber !== null ? zaidnumber : state.zaidnumber
            }
        case 'USER_UPDATE_PROFILE':
            return {
                ...state,
                isLoadingUserRequest: true
            }
        case 'USER_ZA_ID_NUMBER_ERROR':
            return {
                ...state,
                zaidnumberErrorMsg: action.description
            }
        default:
            return state;
    }
}
// SELECTORS
export const selectUserToken = (state) => state.UserStore.token;


// ACTORS
export const doUserStoreLogin = id => ({
    type: 'USER_STORE_LOGIN_API',
    id
});

export const doUserLogout = () => ({
    type: 'USER_LOGOUT'
});

export const getUserLoginMethodByUsername = username => {
    return {
        type: 'USER_FETCH_LOGIN_METHOD_BY_USERNAME',
        username
    }
}

export const getUserPasswordReset = username => {
    return {
        type: 'USER_FETCH_PASSWORD_RESET_BY_USERNAME',
        username
    }
}

export const getUserLoginTokenFromUserNamePassword = (username, password) => {
    return {
        type: 'USER_FETCH_TOKEN_BY_USERNAME_PASSWORD',
        username,
        password
    }
}

export const getUserExistingTokenToResumeSession = () => {
    return {
        type: 'USER_FETCH_TOKEN_FROM_BROWSER'
    }
}

export const getUserProfileInfo = () => {
    return {
        type: 'USER_FETCH_PROFILE_INFO'
    }
}

export const sendUserContactEmail = (name, email, phone, message) => {
    return {
        type: 'USER_SEND_CONTACT_EMAIL',
        name, email, phone, message
    }
}

export const updateUserProfileInfo = (firstname, lastname, sex, birthday, mobile, zaidnumber) => {
    return {
        type: 'USER_UPDATE_PROFILE',
        firstname, lastname, sex, birthday, mobile, zaidnumber
    }
}

export const updateUserZaIdNumber = (zaidnumber) => {
    return {
        type: 'USER_UPDATE_ZA_ID_NUMBER',
        zaidnumber
    }
}
// SAGA REFERENCE

export default UserStore;