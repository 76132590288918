import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from "react";

class CustomDoughnut extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { description: undefined };
        this.resetDescription = this.resetDescription.bind(this);
        this.showDescription = this.showDescription.bind(this);
        // { labels, data, descriptions, colors, unit }
    }

    showDescription(index) {
        console.log('setting?');
        if(index !== this.state.description) {
            console.log('setting!');
            this.setState({
                description: index
            })
        }
    }
    resetDescription() {
        this.setState({
            description: undefined
        })
    }

    render() {
        const backgroundColor = [];
        const borderColor = [];
        const { labels, data, descriptions, colors, unit } = this.props;
        const showDescription = this.showDescription;
        colors.forEach((color) => {
            switch (color) {
                case 'red':
                    backgroundColor.push('rgba(255, 99, 132, 0.2)');
                    borderColor.push('rgba(255, 99, 132, 1)');
                    break;
                case 'blue':
                    backgroundColor.push('rgba(54, 162, 235, 0.2)');
                    borderColor.push('rgba(54, 162, 235, 1)');
                    break;
                case 'aqua':
                    backgroundColor.push('rgba(75, 192, 192, 0.2)');
                    borderColor.push('rgba(75, 192, 192, 1)');
                    break;
                case 'yellow':
                    backgroundColor.push('rgba(255, 206, 86, 0.2)');
                    borderColor.push('rgba(255, 206, 86, 1)');
                    break;
                case 'green':
                    backgroundColor.push('rgba(125, 255, 99, 0.2)');
                    borderColor.push('rgba(71, 177, 94, 1)');
                    break;
                default:
                    backgroundColor.push('rgba(255,255,255,0.2)');
                    borderColor.push('rgba(0,0,0,0.5)');
                    break;

            }
        })

        const labelSettings = {
            name: {
                align: 'top',
                font: { size: 14 },
                color: '#000',
                formatter: function (_, ctx) {
                    return ctx.chart.data.labels[ctx.dataIndex];
                }
            },
            value: {
                align: 'bottom',
                backgroundColor: '#000',
                borderColor: '#000',
                borderWidth: 2,
                borderRadius: 4,
                color: '#fff',
                formatter: function (value, ctx) {
                    return value.toFixed(2) + unit;
                },
                padding: 4
            }
        }

        const pluginsSettings = {
            datalabels: {
                color: function(context) {
                    return context.dataset.backgroundColor;
                },
                font: function(context) {
                    var w = context.chart.width;
                    return {
                        size: w < 512 ? 12 : 14,
                        weight: 'bold',
                    };
                },
                formatter: function(value, context) {
                    return context.chart.data.labels[context.dataIndex];
                }
            }
        }

        return (
        <div className="graph-box">
            <Doughnut
                plugins={[ChartDataLabels]}
                data={
                    {
                        labels,
                        datasets: [
                            {
                                label: 'Total Body Weight',
                                data,
                                backgroundColor,
                                borderColor,
                                borderWidth: 2,
                                datalabels: {
                                    labels: labelSettings
                                }
                            },
                        ]
                    }
                }
                options={
                    {
                        plugins: {
                            ...pluginsSettings,
                            tooltip: {
                                callbacks: { 
                                    label: function(context) {
                                        console.log('clicked', context, context.label);
                                        labels.forEach((val, idx) => {
                                            if (descriptions.length > 0) {
                                                if (val === context.label) {
                                                    console.log('idx');
                                                    showDescription(idx);
                                                }
                                            }
                                        });
                                    }
                                },
                            },
                        }
                    }
                }
            />
            {this.state.description !== undefined ?
                <div className="alert-graph-overlay alert alert-secondary shadow-sm">
                    <button type="button" className="close" onClick={this.resetDescription} aria-hidden="true">×</button>
                    <h4>{labels[this.state.description]}</h4>
                    <p>{descriptions[this.state.description]}</p>
                </div>
            : '' }
        </div>)
    }
}

CustomDoughnut.defaultProps = {
    unit: '',
    descriptions: [],
}

export default CustomDoughnut;