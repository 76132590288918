

function Header() {
  return (
    <>
        <ul className="navbar-nav">
            <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
            </li>
            {/* <li className="nav-item d-none d-sm-inline-block">
                <a className="nav-link">Main Menu</a>
            </li> */}
        </ul>
        <ul className="navbar-nav ml-auto">
            <li className="nav-item" style={{ marginRight: '14px' }}>
                <a style={{ textAlign: 'center' }}>
                    <img src="/static/assets/Full_Dark-svg.svg" style={{ height: '24px' }} />
                </a>
            </li>
        </ul>
    </>
  );
}

export default Header;
