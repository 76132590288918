import validate from 'validate.js';
import { useParams, useNavigate } from 'react-router';

export class FormValidation {
    static validateEmail(emailAddress) {
        const validationResult = validate({from: emailAddress}, {from: { email: true }});
        return validationResult === undefined;
    }
}

export class API {
    static async call(endPoint, method, input = {}, token = undefined) {
        let properties = {
            method,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
        };
        if(method !== 'GET') {
            properties.body = JSON.stringify(input);
        }
        if(token !== undefined) {
            properties.headers['Authorization'] = 'Bearer ' + token;
        }
        const result = await fetch('https://api.bodycodez.com/' + endPoint, properties)
            .then((response) => {
                return response.json().then((res) => {
                    console.log(res);
                    if(!res.success) {
                        if(res.description && res.description.toLowerCase() === 'token expired') {
                            sessionStorage.setItem("expired", "true");
                            window.location.href = "/login";
                        }
                    }
                    return res;
                });
            })
            .catch((error) => {
                console.log('error', error);
                throw error;
            });
        return result;
    }

    static async post(endPoint, input = {}, token = undefined) {
        console.log('REQUEST', endPoint);
        return this.call(endPoint, 'POST', input, token);
    }

    static async get(endPoint, token = undefined) {
        console.log('REQUEST GET', endPoint);
        return this.call(endPoint, 'GET', undefined, token);
    }

    static async patch(endPoint, input = {}, token = undefined) {
        console.log('REQUEST', endPoint);
        return this.call(endPoint, 'PATCH', input, token);
    }
}

export function withRouter(SimpleMap) {
    return (props) => {
        const params = useParams();
        return <SimpleMap {...props} params={params} />;
    };
}

export function withNavigate(SimpleMap) {
  return (props) => {
    const navigate = useNavigate();
    return <SimpleMap {...props} navigate={navigate} />
  }
}