import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserPasswordReset } from '../Reducers/User';
import SingleBlock from '../Layouts/SingleBlock';
import Card from '../Components/Card';
import Loading from '../Components/Loading';
import { FormValidation } from '../Helpers';

class PasswordReset extends React.Component {
    constructor(props) {
        super(props); // {email, handleLoginSubmit}
        this.state = { isLoading: false, inputUsername: '', inputUsernameError: false };
        this.onSubmitPasswordReset = this.onSubmitPasswordReset.bind(this);
    }
        
    onSubmitPasswordReset(e) {
        e.preventDefault();
        if(this.state.inputUsername.length < 1 && this.props.email.length > 0) { // Prevent submitting prop, rather convert to state then re-run function.
            this.setState({
                inputUsername: this.props.email
            });
            return this.onSubmitPasswordReset(e);
        }
        const isValidEmail = FormValidation.validateEmail(this.state.inputUsername);
        if(!isValidEmail) {
            this.setState({
                inputUsernameError: true
            })
            return;
        }
        this.props.dispatch(getUserPasswordReset(this.state.inputUsername));
    }
    
    onUpdateUserName(newValue) {
        this.setState({
            inputUsername: newValue
        });
    }

    render() {
        return (
            <SingleBlock>
                {
                this.props.isForced &&
                    <Card border={false} color="primary single-box-alert">
                        <div className="row text-center">
                            <div className="col-12">
                                <h2>IMPORTANT</h2>
                                <p>In order to use the new version of our platform, please reset your password.</p>
                            </div>
                        </div>
                    </Card>
                }
                <Card border={false} color="transparent">
                    <p className="login-box-msg">Password Reset</p>
                    <div className="row">
                        <Loading isLoading={this.state.isLoading} />
                        {this.props.passwordResetSuccess ? 
                        (
                        <p className="login-box-msg" id="emailInbox">A reset email has been sent to the email address specified.<br /><a href="/">Back to Login</a>&nbsp;|&nbsp;<a href="https://bodycodez.com/contact-us/">Need help?</a></p>
                        ) :
                        (
                        <div className="mt-1 col-12">
                            <form id="loginForm" onSubmit={this.onSubmitPasswordReset} className="form-horizontal">
                                <div className="box-body">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label htmlFor="username" className="control-label">Email</label>
                                            </div>
                                            {
                                            this.props.email.length > 0 ? 
                                            (
                                            <div className="col-sm-12">
                                                <input type="text" value={this.props.email} className="form-control" id="username" placeholder="Email" name="username" disabled={true} />
                                            </div>
                                            ) : 
                                            (
                                            <div className="col-sm-12">
                                                <input type="text" value={this.state.inputUsername} onChange={e => this.onUpdateUserName(e.target.value)} className="form-control" id="username" placeholder="Email" name="username" />
                                                { this.state.inputUsernameError ? <span className="has-error" id="usernameError">Please enter a valid e-mail address</span> : '' }
                                            </div>
                                            )
                                            }
                                        </div>
                                    </div>
                                    <div className="box-footer remove-card-margin">
                                        <button type="submit" className="btn btn-info btn-block btn-lg" id="forgotSubmit"><i className="fa fa-key"></i> Reset Password</button>
                                        {
                                            this.props.email.length > 0 ?
                                            <Link to="/login" className="btn btn-block btn-lg btn-tertiary" id="forgot"><i className="fa fa-arrow-left"></i> Back</Link>
                                            :
                                            ''
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                        )  
                        }
                    </div>
                </Card>
            </SingleBlock>
        );
    }
}

PasswordReset.defaultProps = {
    email: "",
    isForced: false
};

const mapStateToProps = (state) => {
    return {
        email: state.UserStore.username,
        passwordResetSuccess: state.UserStore.passwordResetSuccess
    }
};

export default connect(
    mapStateToProps
)(PasswordReset);