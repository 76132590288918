import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import Main from '../Layouts/Main';
import Card from '../Components/Card';
import StatsCard from '../Components/StatsCard';
import { getUserProfileInfo } from '../Reducers/User';
import { getLatestMeasurementFromDashboard, getMeasurementApprovalList } from '../Reducers/Measurement';
import ApprovalFeed from '../Components/ApprovalFeed';
import { Link } from 'react-router-dom';

const LatestResultSummary = ({count, timestamp }) => {  
    var timeAgo = moment(timestamp).fromNow();
    if (count && count > 0) {
        return (
            <p>
                Test Count: <strong>{count}</strong>
                <br />
                Last Result: <strong><span id="time">{timeAgo}</span></strong>
            </p>
        );
    } else {
        return (
            <div className="col-12 text-center d-flex align-items-center justify-content-center">
                <div>
                    <h2><strong>Watch this space</strong></h2>
                    <p>
                        You're only one scan away from unlocking your profile summary.
                    </p>
                </div>
            </div>
        )
    }
}

class Dashboard extends React.Component {
    componentDidMount() {
        // this.props.dispatch(getUserProfileInfo());
        this.props.dispatch(getLatestMeasurementFromDashboard());
        this.props.dispatch(getMeasurementApprovalList());
    }
    render() {
        console.log('here', this.props);
        return (
            <Main>
                <div className="row">
                    {
                    (this.props.idnumber.length > 0 ?                        
                        (this.props.firstname.length && this.props.gender && this.props.mobile && this.props.birthday && this.props.lastname) ? '' :
                            (
                                <div className="col-md-7 col-lg-4">             
                                    <div class="small-box bg-danger">
                                        <div class="inner">
                                            <h3>Incomplete Profile</h3>
                                            <p>Please complete your profile to improve the accuracy of your scans</p>
                                        </div>
                                        <div class="icon">
                                            <i class="fas fa-user-warning"></i>
                                        </div>
                                        <Link to="/profile" className="small-box-footer">
                                            Complete Profile <i class="fa fa-birthday"></i>
                                        </Link>
                                    </div>         
                                </div>
                            )
                    : '')
                    }
                    <div className="col-md-6 col-lg-5">
                        <Card color="secondary" title={this.props.firstname ? 'Hello, ' + this.props.firstname : 'Hello'} isLoading={!('measurementCount' in this.props.data)} icon={<i className="far fa-smile"></i>}>
                            { ('measurementCount' in this.props.data) ? 
                                <>
                                    <LatestResultSummary count={this.props.data.measurementCount} timestamp={this.props.data.timestamp} />
                                </>
                                : ''
                            }
                        </Card>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <Card isLoading={!(this.props.idnumber.length > 1)} color="pink" title="Your Bodycode">
                            <div className="form-group" >
                                <div className="row">
                                    <div className="col-sm-12">
                                        <span className="text-xl">
                                            {
                                                this.props.idnumber.length > 0 ? this.props.idnumber : '-'
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <StatsCard name="Height" color={'secondary'} icon={<i className="fas fa-ruler-vertical"></i>} value={'height' in this.props.data ? this.props.data.height + 'cm' : undefined} />
                    <StatsCard name="Age" color={'secondary'} icon={<i className="fas fa-birthday-cake"></i>} value={'age' in this.props.data ? Math.round(this.props.data.age) : undefined} />
                    <StatsCard name="Weight" color={'secondary'} icon={<i className="fas fa-weight"></i>} value={'weight' in this.props.data ? this.props.data.weight + 'kg' : undefined} />
                    <StatsCard name="Fitness Score" color={'secondary'} icon={<i className="fas fa-dumbbell"></i>} value={'fitnessScore' in this.props.data ? this.props.data.fitnessScore  + '/100' : undefined} />
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <ApprovalFeed />
                    </div>
                </div>
            </Main>
        );
    }
}

Dashboard.defaultProps = {
    birthday: '',
    email: '',
    firstname: '',
    gender: '',
    idnumber: '',
    lastname: '',
    mobile: '',
    zaidnumber: '',
    isLoadingUserRequest: true,
    data: {}
};

const mapStateToProps = state => ({
    birthday: state.UserStore.birthday,
    email: state.UserStore.email,
    firstname: state.UserStore.firstname,
    gender: state.UserStore.gender,
    idnumber: state.UserStore.idnumber,
    lastname: state.UserStore.lastname,
    mobile: state.UserStore.mobile,
    zaidnumber: state.UserStore.zaidnumber,
    isLoadingUserRequest: state.UserStore.isLoadingUserRequest,
    data: state.MeasurementStore.dashboardData
});

export default connect(
    mapStateToProps
)(Dashboard);