import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import reducer from './Reducers';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './Sagas';
import Router from './Router';
import { composeWithDevTools } from 'redux-devtools-extension';
// import './Styles/adminlte/adminlte.scss';
import './Styles/style.scss';
import 'react-datetime/css/react-datetime.css';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
    reducer,
    process.env.NODE_ENV !== 'production' ? compose(applyMiddleware(sagaMiddleware), composeWithDevTools()) : compose(applyMiddleware(sagaMiddleware))
)
sagaMiddleware.run(rootSaga)

ReactDOM.render(
  (<React.StrictMode>
    <Provider store={store}>
        <Router />
    </Provider>
  </React.StrictMode>),
  document.getElementById('root'), function() {
      console.log('REACTDOM Callback');
  }
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
