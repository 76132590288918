import Loading from './Loading';

const StatsCard = ({name, value, color, icon}) => {
    return (
        <div className="col-6 col-md-3">
            <div className={"small-box bg-" + color}>
                <Loading isLoading={value === undefined} />
                <div className="inner">
                    <h3>{value === undefined ? '-' : value}</h3>
                    <p>{name}</p>
                </div>
                <div className="icon">
                    {icon ? icon : ''}
                </div>
            </div>
        </div>
    );
}

StatsCard.defaultProps = {
    'color': 'info',
    'icon': <i className="fa fa-star"></i>
}

export default StatsCard;
