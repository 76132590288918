import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import ResetPassword from './Pages/ResetPassword';
import Logout from './Pages/Logout';
import Calendar from './Pages/Calendar';
import { connect } from 'react-redux';
import Scan from './Pages/Scan';
import ContactUs from './Pages/ContactUs';
import Profile from './Pages/Profile';
import Register from './Pages/Register';

const Router = ({loginToken}) => {
    return (
        <BrowserRouter>
            <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/upgrade" element={<ResetPassword isForced={true} />} />
            { loginToken.length > 1 ?
            <>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/history" element={<Calendar />} />
                <Route path="/history/:id" element={<Scan />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/profile" element={<Profile />} />
            </> : 
                <Route path="*" element={<Login />} />
            }
            </Routes>
        </BrowserRouter>
    );
};

Router.defaultProps = {
    loginToken: ''
};

const mapStateToProps = ({UserStore}) => {
    return {
        loginToken: UserStore.token,
    }
};

export default connect(
    mapStateToProps
)(Router);