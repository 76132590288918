import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { getUserLoginMethodByUsername, getUserLoginTokenFromUserNamePassword, getUserExistingTokenToResumeSession } from '../Reducers/User';
import SingleBlock from '../Layouts/SingleBlock';
import Card from '../Components/Card';
import { FormValidation } from '../Helpers';

class Login extends React.Component {
    constructor(props) {
        super(props); // {email, handleLoginSubmit}
        let isExpired = sessionStorage.getItem("expired");
        isExpired = isExpired === undefined ? false : true;
        
        if(isExpired) {
            sessionStorage.removeItem("expired");
        }

        this.state = { inputUsername: '', inputUsernameError: false, inputPassword: '', inputPasswordError: false, expired: isExpired };
        this.onSubmitLoginForm = this.onSubmitLoginForm.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getUserExistingTokenToResumeSession())
    }

    onSubmitLoginForm(e) {
        e.preventDefault();
        this.setState({
            inputUsernameError: false,
            inputPasswordError: false
        })
        if(this.props.isUserSSO === undefined) {
            const isValidEmail = FormValidation.validateEmail(this.state.inputUsername);
            if(!isValidEmail) {
                this.setState({
                    inputUsernameError: true
                })
                return;
            }
            this.props.dispatch(getUserLoginMethodByUsername(this.state.inputUsername));
        } else {
            const isValidEmail = FormValidation.validateEmail(this.state.inputUsername);
            let emailError = false, passwordError = false;
            if(!isValidEmail) {
                emailError = true;
            }
            if(this.state.inputPassword.length < 2) { // no one should have a 2 char password anyway
                passwordError = true
            }
            this.setState({
                inputUsernameError: emailError,
                inputPasswordError: passwordError
            })
            this.props.dispatch(getUserLoginTokenFromUserNamePassword(this.state.inputUsername, this.state.inputPassword));            
        }
    }
    
    onUpdateUserName(newValue) {
        this.setState({
            inputUsername: newValue
        })
    }
    onUpdatePassword(newValue) {
        this.setState({
            inputPassword: newValue
        })
    }

    render() {
        return (
            <SingleBlock>
                {
                    this.props.loginToken.length > 0 ? <Navigate to="/dashboard" /> : ''
                }
                {
                    this.props.isUserSSO ?  <Navigate to="/upgrade" /> : ''
                }
                <Card border={false} color="transparent" isLoading={this.props.isLoading} isSuccess={this.props.loginToken.length > 0}>
                    <p className="login-box-msg">Welcome to BODYCODEZ</p>
                    <div className="row">
                        <div className="mt-1 col-12">
                            <form id="loginForm" onSubmit={this.onSubmitLoginForm} className="form-horizontal">
                                <div className="box-body">
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label htmlFor="username" className="control-label">Email</label>
                                            </div>
                                            <div className="col-sm-12">
                                                <input type="text" value={this.state.inputUsername} onChange={e => this.onUpdateUserName(e.target.value)} className="form-control" id="username" placeholder="Email" name="username" />
                                                { this.state.inputUsernameError ? <span className="has-error" id="usernameError">Please enter a valid e-mail address</span> : '' }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.props.isUserSSO === false ?
                                        (<div className="form-group" id="passwordSection">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <label htmlFor="password" className="control-label">Password</label>
                                                </div> 
                                                <div className="col-sm-12">
                                                    <input type="password" value={this.state.inputPassword} onChange={e => this.onUpdatePassword(e.target.value)} className="form-control" id="password" placeholder="Password" name="password" />
                                                    <i className="fa fa-eye" style={{ marginRight: '5px', marginTop: '-26px', position: 'relative', zIndex: 2, float: 'right' }} id="showPassword"></i>
                                                    { this.state.inputPasswordError ? <span className="has-error hidden" id="passwordError">Please enter a valid password</span> : '' }
                                                </div>
                                            </div>
                                        </div>) : ''
                                    }
                                    { this.props.failedLoginMessage && !this.props.isLoading ? (
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-sm-12 text-center">
                                                    <span className="has-error" id="error-login">{this.props.failedLoginMessage}</span>
                                                </div>
                                            </div>
                                        </div>)
                                        :
                                        ''
                                    }
                                </div>
                                <div className="box-footer remove-card-margin">
                                    { 
                                    this.props.isUserSSO === false ? (
                                    <>
                                        <button type="submit" className="btn btn-block btn-success btn-lg mb-2 mt-2" id="submit"><i className="fa fa-user"></i> Sign in</button>
                                        <Link to="/resetpassword"><button type="button" className="btn btn-block btn-warning btn-lg mb-2 mt-2" id="forgot"><i className="fa fa-question"></i> Forgot Password</button></Link>
                                    </>
                                    ) : 
                                    <button type="submit" className="btn btn-block btn-success btn-lg mb-2 mt-2" id="continue"><i className="fa fa-sign-in-alt"></i> Continue</button>
                                    }

                                    <a href="/register" className="btn btn-block btn-primary btn-lg mb-2 mt-2"><i className="fa fa-user-plus"></i> Register</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </Card>
            </SingleBlock>
        );
    }
}

Login.defaultProps = {
    email: "",
    isUserSSO: undefined,
    failedLoginMessage: undefined,
    isLoading: false,
    loginToken: ''
};

const mapStateToProps = ({UserStore}) => {
    return {
        isUserSSO: UserStore.isSSO,
        failedLoginMessage: UserStore.loginFailedMessage,
        isLoading: UserStore.isLoadingUserRequest,
        loginToken: UserStore.token
    }
};

export default connect(
    mapStateToProps
)(Login);