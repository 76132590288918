import React from 'react';
import { connect } from 'react-redux';
import Main from '../Layouts/Main';
import Card from '../Components/Card';
import { updateUserProfileInfo } from '../Reducers/User';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Datetime from 'react-datetime';

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newFirstName: '',
            newLastName: '',
            newSex: '',
            newZaIdNumber: '',
            newDateOfBirth: '',
            newCellNumber: '',
            loadingSuccess: false
        }
        this.handleSubmitUpdateInfoForm = this.handleSubmitUpdateInfoForm.bind(this);
    }

    handleUpdateFirstName(value) {
        this.setState({
            newFirstName: value
        })
    }

    handleUpdateLastName(value) {
        this.setState({
            newLastName: value
        })
    }

    handleUpdateSex(value) {
        if(this.props.gender === null) { // Are we allowed to edit this field?
            this.setState({
                newSex: value
            })
        }
    }

    handleUpdateZaId(value) {
        console.log('zaidnumebr', this.state.newZaIdNumber);
        if(this.props.zaidnumber.length < 1) { // Are we allowed to edit this field?
            // let birthday = undefined;
            // if(this.props.birthday === null) {
            //     if(value.length >= 6) {
            //         const bdaySub = value.substring(0,6)
            //         const currentYear = String(new Date().getFullYear()).substring(2, 4);
            //         const birthYearPrefix = parseInt(bdaySub.substring(0,2)) > currentYear  ? '19' : '20'; // assume the year if the date is older than 
            //         birthday = birthYearPrefix + bdaySub.substring(0,2) + '-' + bdaySub.substring(2, 4) + '-' + bdaySub.substring(4, 6);
            //     }
            // }
            // fetch Date Of Birth if need be
            this.setState({
                //newDateOfBirth: birthday,
                newZaIdNumber: value
            })
        }
    }

    handleUpdateBirthday(value) {
        if(this.props.birthday.length < 1) { // Are we allowed to edit this field?
            this.setState({
                newDateOfBirth: moment(value).format('YYYY-MM-DD')
            })
        }
    }

    handleUpdateCellNumber(value) {
        this.setState({
            newCellNumber: value
        })
    }

    handleSubmitUpdateInfoForm(e) {
        e.preventDefault();
        console.log(this.props);
        const firstName = this.state.newFirstName === '' ? this.props.name : this.state.newFirstName;
        const lastName = this.state.newLastName === '' ? this.props.lastname : this.state.newLastName;
        const sex = this.state.newSex === '' ? this.props.gender : this.state.newSex;
        const birthday = this.state.newDateOfBirth === '' ? this.props.birthday : this.state.newDateOfBirth;
        const mobile = this.state.newCellNumber === '' ? this.props.mobile : this.state.newCellNumber;
        const zaidnumber = this.state.newZaIdNumber === '' ? this.props.zaidnumber : this.state.newZaIdNumber;
        this.props.dispatch(updateUserProfileInfo(firstName, lastName, sex, birthday, mobile, zaidnumber));
    }

    componentDidUpdate(prevProps) {
        if(prevProps.isLoading && !this.props.isLoading) {
            this.setState({
                loadingSuccess: true
            })
            setTimeout(() => {
                this.setState({
                    loadingSuccess: false
                })
            }, 2500);
        }
    }

    render() {
        return (
            <Main>
                <Card color="white" isSuccess={this.state.loadingSuccess} isLoading={this.props.email === '' || this.props.isLoading}>
                    <div className="box box-warning">
                        <div className="box-header with-border">
                            <h3 className="box-title">Profile</h3>
                            <h6>Fields that are not editable can be changed by <Link to="/contactus">Contacting Support</Link></h6>
                        </div>
                        <div className="box-body">
                            <form onSubmit={this.handleSubmitUpdateInfoForm}>
                                <div className="row">
                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label>E-mail</label>
                                            <input type="text" value={this.props.email} className="form-control input-lg" readOnly />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-2">
                                        <div className="form-group">
                                            <label>Bodycode</label>
                                            <input type="text" value={this.props.idnumber} className="form-control" readOnly />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>First Name(s)</label>
                                            <input type="text" value={this.state.newFirstName ? this.state.newFirstName : this.props.name} onChange={(e) => this.handleUpdateFirstName(e.target.value)} className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Last Name</label>
                                            <input type="text" value={this.state.newLastName ? this.state.newLastName : this.props.lastname} onChange={(e) => this.handleUpdateLastName(e.target.value)} className="form-control" />
                                        </div>
                                    </div>          
                                    <div className="col-md-6 col-lg-2">
                                        <div className="form-group">
                                            <label>Sex</label>
                                        {
                                        this.props.gender ?
                                        (
                                            <select className="form-control" value={this.props.gender.toLowerCase()} readOnly disabled>
                                                <option value="female">Female</option>
                                                <option value="male">Male</option>
                                            </select>
                                        ) 
                                        :
                                            <select className="form-control" onChange={(e) => this.handleUpdateSex(e.target.value)}>
                                                <option>- SELECT -</option>
                                                <option value="female">Female</option>
                                                <option value="male">Male</option>
                                            </select>
                                        }
                                        </div>
                                    </div>    
                                </div>
                                <div className="row">                      
                                    <div className="col-md-6 col-lg-4">
                                        <div className="form-group">
                                            <label>South African ID Number</label>
                                            { this.props.zaidnumber ?
                                            (
                                                <input type="text" value={this.props.zaidnumber} className="form-control" readOnly />
                                            )
                                            :
                                            (
                                                <input type="text" value={this.state.newZaIdNumber ? this.state.newZaIdNumber : ''} onChange={(e) => this.handleUpdateZaId(e.target.value)} className={"form-control " + (this.props.zaIdNumberErrorMessage ? 'is-invalid' : '')} />
                                            )}
                                            {this.props.zaIdNumberErrorMessage ? <span className="error invalid-feedback">{this.props.zaIdNumberErrorMessage}</span> : '' }
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="form-group">
                                            <label>Date of Birth</label>
                                            { this.props.birthday ?
                                            (
                                                <input type="text" value={moment(this.props.birthday).format('YYYY-MM-DD')} className="form-control" readOnly /> 
                                            )
                                            :
                                            (
                                                <Datetime initialViewMode={'years'} 
                                                initialViewDate={moment(new Date(1990, 1, 1))} 
                                                value={this.state.newDateOfBirth ? this.state.newDateOfBirth : ''} onChange={(date) => this.handleUpdateBirthday(date)} timeFormat={false} dateFormat={"YYYY-MM-DD"}/>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-3">
                                        <div className="form-group">
                                            <label>Cell Number</label>
                                            <input type="text" value={this.state.newCellNumber ? this.state.newCellNumber : this.props.mobile} onChange={(e) => this.handleUpdateCellNumber(e.target.value)} className="form-control input-lg" />
                                        </div>
                                    </div>    
                                    <div className="col-md-12">
                                        <div className="form-group text-right">
                                            <input type="submit" value="Update" className="btn btn-success" />
                                        </div>
                                    </div>    
                                </div>
                            </form>
                        </div>
                    </div>
                </Card>
            </Main>
        );
    }
}

Profile.defaultProps = {
    name: '',
    lastname: '',
    email: '',
    phone: '',
    idnumber: '',
    zaidnumber: '',
    birthday: '',
    gender: '',
    zaIdNumberErrorMessage: '',
    mobile: '',
};

const mapStateToProps = ({ UserStore }) => ({
    name: UserStore.firstname,
    lastname: UserStore.lastname,
    email: UserStore.email,
    phone: UserStore.phone,
    idnumber: UserStore.idnumber,
    zaidnumber: UserStore.zaidnumber,
    birthday: UserStore.birthday,
    gender: UserStore.gender,
    mobile: UserStore.mobile,
    isLoading: UserStore.isLoadingUserRequest,
    zaIdNumberErrorMessage: UserStore.zaidnumberErrorMsg
});

export default connect(
    mapStateToProps
)(Profile);