import Loading from "./Loading";

function Card({color, border, icon, title, children, isLoading, isSuccess}) {
    const borderSetting = border === undefined ? "" : !border ? "-0" : border;
    const shadowSetting = border === undefined ? "" : !border ? "shadow-none" : "";
    const addClasses = "bg-" + color + " border" + borderSetting + " " + shadowSetting;
    return (
        <>
            <div className={"info-box mb-3 " + addClasses}>
                <Loading isLoading={isLoading} isSuccess={isSuccess} />
                { icon === undefined ? '' : <span className="info-box-icon">{icon}</span> }
                <div className="info-box-content">
                <span className="info-box-text">{title}</span>
                <span className="info-box-number">{children}</span>
                </div>
            </div>
        </>
    );
}

Card.defaultProps = {
   color: 'success',
   icon: undefined,
   isLoading: false,
   isSuccess: false
}

export default Card;
