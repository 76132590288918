import Header from '../Components/Header.js'
import SideMenu from '../Components/SideMenu.js'

const Main = (props) => {
    /* eslint-disable */
    closeSidebar();
    /* eslint-enable */
  return (
    <div className="wrapper">
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <Header />
        </nav>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <SideMenu />
        </aside>
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    {props.children}
                </div>
            </div>
        </div>
        <div>
            <footer className="main-footer" style={{ textAlign: 'center', color: '#54a2a4' }}>
                <strong>Copyright &copy; 2022 BodyCodeZ</strong>
            </footer>
        </div>
    </div>
  );
}

export default Main;
