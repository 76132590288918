const SingleBlock = (props) => {
    return (
    <div className="hold-transition login-page">
        <div className={props.big ? "" : "login-box"}>
            <div className="card card-outline card-primary border-primary">
                <div className="card-header text-center">
                    <a href="/" className='text-center'>
                        <img src="/static/assets/Full_Dark-svg.svg" style={{ height: '48px' }} alt="full-dark" />
                    </a>
                </div>
                {props.children}
            </div>
        </div>
    </div>
    );
}

export default SingleBlock;
